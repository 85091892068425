import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, fontPlayfairDisplay } from "@product/scmp-sdk";

import { Container as HeadlineContainer } from "scmp-app/components/content/content-headline/styles";
import { EntityLink } from "scmp-app/components/entity-link";
import { TopicLink } from "scmp-app/components/topic/topic-link";

export const CoverEntityLink = styled(EntityLink)`
  display: block;
`;
export const StyledEntityLink = styled(EntityLink)`
  color: #222222;
`;

export const Topic = styled(TopicLink)`
  margin-block-start: 10px;

  color: #222222;
  font-weight: 500;
  font-size: 12px;
  font-family: ${fontBarlowSemiCondensed};
  line-height: 14.4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;
export const Cover = styled.div`
  ${CoverEntityLink} {
    inline-size: 100%;
  }
`;
export const Headline = styled.div`
  ${HeadlineContainer} {
    font-weight: 400;
    font-size: 24px;
    font-family: ${fontPlayfairDisplay};
    line-height: 30px;

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 32px;
      line-height: 38px;
    }
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  text-align: center;

  ${props => props.theme.breakpoints.up("tablet")} {
    text-align: start;
  }
`;
