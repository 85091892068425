import { useAsync, useMountEffect } from "@react-hookz/web";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";

import { config } from "scmp-app/data";
import type { postMagazineConfigAppConfigQuery } from "scmp-app/queries/__generated__/postMagazineConfigAppConfigQuery.graphql";

export const usePostMagazineConfig = () => {
  const environment = useRelayEnvironment();
  const [state, actions] = useAsync(async () => {
    const data = await fetchQuery<postMagazineConfigAppConfigQuery>(
      environment,
      graphql`
        query postMagazineConfigAppConfigQuery {
          appConfig(filter: { entityId: "post_magazine" }) {
            json
          }
        }
      `,
      {},
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

    if (!data?.appConfig?.json) return;
    const raw = data.appConfig.json as PostMagazineConfig;
    return config.general.env === "production" ? raw.production : raw.dev;
  });

  useMountEffect(() => {
    void actions.execute();
  });

  return { postMagazineConfigState: state };
};

type PostMagazineConfig = AppConfig<{
  tagline: string;
}>;
