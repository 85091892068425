// cSpell:disable
import { section } from "scmp-app/data/section";

export const useSeoImage = (entityUuid: Nullish<string>) => {
  const SITE_URL = "https://www.scmp.com";
  switch (entityUuid) {
    case section.news.hongKong.entityUuid:
      return `${SITE_URL}/static/img/og/OG_hongkong.png`;
    case section.news.china.entityUuid:
      return `${SITE_URL}/static/img/og/OG_china.png`;
    case section.news.asia.entityUuid:
      return `${SITE_URL}/static/img/og/OG_asia.png`;
    case section.news.world.entityUuid:
      return `${SITE_URL}/static/img/og/OG_world.png`;
    case section.economy.entityUuid:
      return `${SITE_URL}/static/img/og/OG_economy.png`;
    case section.comment.entityUuid:
      return `${SITE_URL}/static/img/og/OG_comment.png`;
    case section.lifestyle.entityUuid:
      return `${SITE_URL}/static/img/og/OG_lifestyle.png`;
    case section.tech.entityUuid:
      return `${SITE_URL}/static/img/og/OG_tech.png`;
    case section.sport.entityUuid:
      return `${SITE_URL}/static/img/og/OG_sport.png`;
    case section.thisWeekInAsia.entityUuid:
      return `${SITE_URL}/static/img/og/OG_twia.png`;
    case section.podcasts.entityUuid:
      return `${SITE_URL}/static/img/og/OG_podcast_index.png`;
    case section.postMagazine.entityUuid:
      return `${SITE_URL}/static/img/og/OG_postmag.png`;
    case section.abacus.entityUuid:
      return `${SITE_URL}/static/img/og/OG_abacus.png`;
    case section.thisWeekInAsia.opinion.entityUuid:
      return `${SITE_URL}/static/img/og/OG_opinion_index.png`;
    case section.betterLife.entityUuid:
      return `${SITE_URL}/static/img/og/OG_betterlife.png`;
    case section.scmpFilms.entityUuid:
      return `${SITE_URL}/static/img/og/OG_scmp_films.png`;
    case section.business.entityUuid:
      return `${SITE_URL}/static/img/og/OG_business.png`;
    default:
      return null;
  }
};
