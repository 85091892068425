/**
 * @generated SignedSource<<41faf96a0a076ecb8aa40d4ebe4b2543>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type postMagazinePrimaryContent$data = {
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "postMagazinePrimaryContent";
};
export type postMagazinePrimaryContent$key = {
  readonly " $data"?: postMagazinePrimaryContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"postMagazinePrimaryContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "postMagazinePrimaryContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize1200x800",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize540x360",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize768x768",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withTopicLink",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "02b6f762d421e0be43da64c7f25601f4";

export default node;
