export type TrackViewedPercentageSchema = {
  attribs: TrackViewedPercentageSchemaAttributes;
  type: typeof TrackViewedPercentageSchemaType;
};

export const TrackViewedPercentageSchemaType = "track-viewed-percentage";

export type TrackViewedPercentageSchemaAttributes = {
  percentage: string;
};

export function isTrackViewedPercentageSchema(
  node: SchemaNode,
): node is TrackViewedPercentageSchema {
  return node.type === TrackViewedPercentageSchemaType;
}

export type TrackViewedPercentageSchemaRow = {
  row: number;
} & TrackViewedPercentageSchema;

export function defineTrackViewedPercentageSchema(options: {
  percentage: string;
  row: number;
}): TrackViewedPercentageSchemaRow {
  const { percentage, row } = options;
  return {
    attribs: {
      percentage,
    },
    row,
    type: "track-viewed-percentage",
  };
}
