import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { usePostMagazineConfig } from "scmp-app/lib/app/hooks/post-magazine-config";
import type { headerPostMagazineQuery$key } from "scmp-app/queries/__generated__/headerPostMagazineQuery.graphql";

import {
  Container,
  LogoContainer,
  LogoPostMagazine,
  LogoScmpSvg,
  StyledEntityOnelineMenu,
  TagLine,
  TagLineText,
} from "./styles";

export type Props = {
  className?: string;
  reference: headerPostMagazineQuery$key;
};
export const PostMagazineHeader: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment headerPostMagazineQuery on Query {
        postMagSection: section(filter: { entityId: "71" }) {
          subSections {
            items {
              edges {
                ...entityOnelineMenuQueueItemsEdge
              }
            }
          }
        }
      }
    `,
    reference_,
  );
  const { postMagazineConfigState } = usePostMagazineConfig();
  const tagline = postMagazineConfigState.result?.tagline;

  return (
    <Container className={className}>
      <LogoContainer>
        <LogoScmpSvg />
        <LogoPostMagazine />
      </LogoContainer>
      <StyledEntityOnelineMenu reference={data.postMagSection.subSections?.items?.edges ?? []} />
      {!!tagline && (
        <TagLine>
          <TagLineText>{tagline}</TagLineText>
        </TagLine>
      )}
    </Container>
  );
};

PostMagazineHeader.displayName = "PostMagazineHeader";
